
    import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
    import Util from '@/lib/util'
    import PageRequest from '@/store/entities/page-request'
    import JobTemplateForm from './jobtemplate-form.vue'
    import SortingModel from "@/store/entities/sortingmodel";
    import AbpBase from "@/lib/abpbase";
    import JobTemplateDto from "@/store/entities/jobcontext/jobtemplate";


    @Component({
        components: {JobTemplateForm}
    })
    export default class JobTemplate extends AbpBase {
        async edit(id: number) {
            this.$store.commit('jobTemplate/edit', await this.$store.dispatch({
                type: 'jobTemplate/get',
                data: id
            }));
            this.editModalShow = true;
        }

        pageRequest: PageRequest = new PageRequest();

        createModalShow: boolean = false;
        editModalShow: boolean = false;

        get list() {
            return this.$store.state.jobTemplate.list;
        };

        async search() {
            this.$store.commit('jobTemplate/setCurrentPage', 1);
            await this.getPage();
        }

        get loading() {
            return this.$store.state.jobTemplate.loading;
        }

        async create() {
            let jobTemplate = new JobTemplateDto();
            jobTemplate.nodes = await this.$store.dispatch({type: 'node/jobTemplateNodes'});
            this.$store.commit('jobTemplate/edit', jobTemplate);
            this.createModalShow = true;
        }

        pageChange(page: number) {
            this.$store.commit('jobTemplate/setCurrentPage', page);
            this.getPage();
        }

        pageSizeChange(pageSize: number) {
            this.$store.commit('jobTemplate/setPageSize', pageSize);
            this.getPage();
        }

        async getPage() {
            this.pageRequest.maxResultCount = this.pageSize;
            this.pageRequest.skipCount = (this.currentPage - 1) * this.pageSize;

            await this.$store.dispatch({
                type: 'jobTemplate/getAll',
                data: this.pageRequest
            })
        }

        get pageSize() {
            return this.$store.state.jobTemplate.pageSize;
        }

        get totalCount() {
            return this.$store.state.jobTemplate.totalCount;
        }

        get currentPage() {
            return this.$store.state.jobTemplate.currentPage;
        }

        async changeSort(data) {
            if (this.pageRequest.sorting == null) {
                this.pageRequest.sorting = [];
            }
            let item = this.pageRequest.sorting.find(x => x.fieldName === data.key)
            if (item == null) {
                let sortingModel = new SortingModel();
                sortingModel.fieldName = data.key;
                sortingModel.isDescendingDirection = data.order === "desc";
                this.pageRequest.sorting.push(sortingModel)
            } else {
                item.isDescendingDirection = data.order === "desc";
            }
            await this.getPage()
        }

        columns = [{
            title: this.L('Name'),
            key: 'name',
            sortable: 'custom'
        }, {
            title: this.L('Actions'),
            key: 'Actions',
            width: 150,
            render: (h: any, params: any) => {
                return h('div', [
                    h('Button', {
                        props: {
                            type: 'primary',
                            size: 'small'
                        },
                        style: {
                            marginRight: '5px'
                        },
                        on: {
                            click: async () => {
                                await this.edit(params.row.id);
                            }
                        }
                    }, this.L('Edit')),
                    h('Button', {
                        props: {
                            type: 'error',
                            size: 'small'
                        },
                        on: {
                            click: async () => {
                                this.$Modal.confirm({
                                    title: this.L('Tips'),
                                    content: this.L('Delete Job template Confirm'),
                                    okText: this.L('Yes'),
                                    cancelText: this.L('No'),
                                    onOk: async () => {
                                        await this.$store.dispatch({
                                            type: 'jobTemplate/delete',
                                            data: params.row
                                        })
                                        await this.getPage();
                                    }
                                })
                            }
                        }
                    }, this.L('Delete'))
                ])
            }
        }]

        async created() {
            await this.getPage();
        }
    }
