
    import {Component, Prop} from 'vue-property-decorator';
    import Util from '../../../../lib/util'
    import AbpBase from '../../../../lib/abpbase'
    import JobTemplate from '../../../../store/entities/jobcontext/jobtemplate'
    import {_} from 'vue-underscore';
    import JobTemplateSelectedNode from "@/store/entities/jobcontext/jobtemplateselectednode";

    @Component
    export default class DocumentForm extends AbpBase {
        @Prop({type: Boolean, default: false}) value: boolean;
        @Prop({type: Boolean, default: false}) public toEdit: boolean;
        jobTemplate: JobTemplate = new JobTemplate();
        private fields: any;
        private selectedFields: Array<JobTemplateSelectedNode> = new Array<JobTemplateSelectedNode>();

        data() {
            return {
                nodeList: [],
                fields: []
            }
        };

        async updateFields() {
            this.selectedFields = await this.$store.dispatch('node/fields', this.jobTemplate.nodes.filter(x => x._checked).map(a => a.nodeId));
            this.jobTemplate.customFields = this.jobTemplate.customFields.filter(x => !this.selectMany(this.selectedFields, c => c.fields).map(c => c.fieldId).includes(x));
            await this.getNotSelectedFields();
        }

        selectMany<TIn, TOut>(input: TIn[], selectListFn: (t: TIn) => TOut[]): TOut[] {
            return input.reduce((out, inx) => {
                out.push(...selectListFn(inx));
                return out;
            }, new Array<TOut>());
        }

        get list() {
            return _.chain(this.jobTemplate.nodes).sortBy('order').sortBy(x => !x._checked).value();
        };

        async getSelectedFields() {
            this.selectedFields = await this.$store.dispatch('node/fields', this.jobTemplate.nodes.filter(x => x._checked).map(a => a.nodeId));
        }

        async getNotSelectedFields() {
            this.fields = await this.$store.dispatch('node/missedFields', this.jobTemplate.nodes.filter(x => x._checked).map(a => a.nodeId));
        }

        save() {
            (this.$refs.jobTemplateForm as any).validate(async (valid: boolean) => {
                if (valid) {
                    await this.$store.dispatch({
                        type: this.toEdit ? 'jobTemplate/update' : 'jobTemplate/create',
                        data: this.getRequestData()
                    });
                    (this.$refs.jobTemplateForm as any).resetFields();
                    this.$emit('save-success');
                    this.$emit('input', false);
                }
            })
        }

        getRequestData() {
            this.jobTemplate.nodes = this.jobTemplate.nodes.filter(x => x._checked);
            this.jobTemplate.fields = this.jobTemplate.customFields.concat(this.selectMany(this.selectedFields, c => c.fields).map(c => c.fieldId)).filter((value, index, self) => self.indexOf(value) === index);
            return this.jobTemplate;
        }

        cancel() {
            (this.$refs.jobTemplateForm as any).resetFields();
            this.$emit('input', false);
        }

        async visibleChange(value: boolean) {
            if (!value) {
                this.$emit('input', value);
            } else {
                this.jobTemplate = Util.extend(true, {}, this.$store.state.jobTemplate.editJobTemplate);
                if (this.toEdit) {
                    await this.getSelectedFields();
                }
                await this.getNotSelectedFields();
            }
        }

        columns = [{
            width: 60,
            align: 'center',
            key: '_checked',
            slot: '_checked'
        }, {
            title: this.L('Name'),
            key: 'nodeName'
        }, {
            title: this.L('Order'),
            key: 'order',
            slot: 'order'
        }]

        jobTemplateRule = {
            name: [{required: true, message: this.L('FieldIsRequired', undefined, this.L('Name')), trigger: 'blur'}]
        }
    }
